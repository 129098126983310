import React from 'react'
import Loading from "./Loading.jsx";
import "../scss/loadingPage.scss"

const LoadingPage = () => {
  return (
    <div className='loadingPage_container'>
      <Loading/>
    </div>
  )
}

export default LoadingPage
