import React, { useState, useEffect } from 'react';

const SuspenseWithFixedLoading = ({ children, fallback, loadingTime = 100}) => {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsReady(true);
        }, loadingTime);

        return () => clearTimeout(timer);
    }, [loadingTime]);

    return (
        <React.Suspense fallback={fallback}>
            {isReady ? children : fallback}
        </React.Suspense>
    );
};

export default SuspenseWithFixedLoading;
