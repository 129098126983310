import React from 'react';
import { Suspense, lazy } from "react";
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import LoadingPage from './components/LoadingPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SuspenseWithFixedLoading from './components/SuspenseWithFixedLoading';  // Import the custom SuspenseWithFixedLoading component

const Homepage = lazy(() => import('./Pages/Homepage'));
const Blogpost = lazy(() => import('./Pages/Blogpost'));
const Login = lazy(() => import('./components/login/Login'));
const Dashboard = lazy(() => import('./components/Dasboard/Dashboard'));
const Posts = lazy(() => import('./Pages/Posts'));
const ForgotPassword = lazy(() => import('./components/login/ResetPassword'));

function App() {
  return (
    <div className="App">
      <Router>
      <Suspense fallback={<LoadingPage />}>
       
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/blog/:id' element={<Blogpost />} />
            <Route path='/posts' element={<Posts />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route element={<PrivateRoute />}>
              <Route path='/dashboard' element={<Dashboard />} />
            </Route>
          </Routes>
          </Suspense>
      </Router>
    </div>
  );
}

export default App;
